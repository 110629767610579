<template>
  <div>
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 搜索与添加区域 -->
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input placeholder="请输入内容" v-model="params.keyWord" clearable @clear="getUserList">
            <el-button slot="append" icon="el-icon-search" @click="getUserList"></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="showAddClick()">添加账号</el-button>
        </el-col>
      </el-row>
      <!-- 账号列表区域 -->
      <el-table :data="records" border stripe :height="innerHeight">
        <el-table-column type="index"></el-table-column>
        <el-table-column label="账号ID" width="150" prop="userId"></el-table-column>
        <el-table-column label="用户昵称" prop="nickName" width="150"></el-table-column>
        <el-table-column label="用户名" prop="userName" width="150"></el-table-column>
        <el-table-column label="角色" prop="roleName"></el-table-column>
        <el-table-column label="归属公司" prop="companyName"></el-table-column>
        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditClick(scope.row)"></el-button>
            <!-- 分配角色按钮 -->
            <el-tooltip effect="dark" content="分配角色" placement="top" :enterable="false">
              <el-button type="warning" icon="el-icon-setting" size="mini" @click="setRole(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="修改密码" placement="top" :enterable="false">
              <el-button type="success" icon="el-icon-edit" size="mini" @click="passwordClick(scope.row)"></el-button>
            </el-tooltip>
            <!-- 删除按钮 -->
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteInfoClick(scope.row)"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 添加账号的对话框 -->
    <comDialog title="添加账号" :isDialog="addDialogVisible" @dialogClose="addDialogClose" @dialogConfirmClick="addUser">
      <el-form :model="addForm" ref="addFormRef" :rules="addFormRules" label-width="100px">
        <el-form-item label="账号名" prop="userName">
          <el-input v-model="addForm.userName"></el-input>
        </el-form-item>
        <el-form-item label="昵称" prop="nickName">
          <el-input v-model="addForm.nickName"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="addForm.password"></el-input>
        </el-form-item>
        <el-form-item label="公司名称" prop="companyCode">
          <el-select v-model="addForm.companyCode" placeholder="请选择">
            <el-option v-for="item in companyList" :key="item.companyCode" :label="item.companyName" :value="item.companyCode"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-radio v-model="addForm.sex" label="0">男</el-radio>
          <el-radio v-model="addForm.sex" label="1">女</el-radio>
          <el-radio v-model="addForm.sex" label="2">其他</el-radio>
        </el-form-item>
        <el-form-item label="是否启用" prop="status">
          <el-radio v-model="addForm.status" label="0">正常</el-radio>
          <el-radio v-model="addForm.status" label="1">停用</el-radio>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="addForm.email" placeholder="请输入邮箱"></el-input>
        </el-form-item>

        <el-form-item label="手机" prop="phonenumber">
          <el-input v-model="addForm.phonenumber" placeholder="请输入手机号"></el-input>
        </el-form-item>
      </el-form>
    </comDialog>

    <!-- 编辑账号的对话框 -->
    <comDialog :isDialog="editDialogVisible" title="编辑账号" @dialogClose="editDialogClose" @dialogConfirmClick="updateById">
      <el-form :model="editForm" ref="editFormRef" :rules="editFormRules" label-width="100px">
        <el-form-item label="账号名" prop="userName">
          <el-input v-model="editForm.userName"></el-input>
        </el-form-item>
        <el-form-item label="昵称" prop="nickName">
          <el-input v-model="editForm.nickName"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="editForm.email"></el-input>
        </el-form-item>
        <el-form-item label="手机" prop="phonenumber">
          <el-input v-model="editForm.phonenumber"></el-input>
        </el-form-item>
      </el-form>
    </comDialog>

    <!-- 分配角色的对话框 -->
    <comDialog title="分配角色" :isDialog="isRoleDialogVisible" @dialogClose="addSaveRoleInfoDialogClose" @dialogConfirmClick="saveRoleInfo">
      <el-form :model="selectedRoleId" :rules="userRoleFormRules" label-width="100px">
        <p>当前的账号：{{ userInfo.nickName }}</p>
        <p>当前的角色：{{ userInfo.roleName }}</p>
        <el-form-item label="分配角色：" prop="selectedRoleId">
          <el-select v-model="selectedRoleId" placeholder="请选择">
            <el-option v-for="item in rolesList" :key="item.roleId" :label="item.roleName" :value="item.roleId"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </comDialog>
    <!-- 修改密码的对话框 -->
    <comDialog title="修改密码" :isDialog="isPassword" @dialogClose="isPasswordDialogClose" @dialogConfirmClick="updatePasswordClick">
      <el-form :model="updatePasswordParams" ref="updatePasswordParamsRef" :rules="updatePasswordParamsRules" label-width="100px">
        <el-form-item label="原密码" prop="original">
          <el-input v-model="updatePasswordParams.original" placeholder="请输入之前的密码"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="password">
          <el-input v-model="updatePasswordParams.password" placeholder="请输入新密码"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="confirm">
          <el-input v-model="updatePasswordParams.confirm" placeholder="请输入确认密码"></el-input>
        </el-form-item>
      </el-form>
    </comDialog>
  </div>
</template>

<script>
import comDialog from '@/components/common/dialog'
import { commonMixin } from '@/utils/mixin/index.js'
const checkEmail = (rule, value, callback) => {
  let reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
  if (value && !reg.test(value)) {
    callback(new Error('请输入正确的邮箱'))
  } else {
    callback()
  }
}
const checkPhone = (rule, value, callback) => {
  if (value && !/^1\d{10}$/.test(value)) {
    callback(new Error('手机号格式错误'))
  } else {
    callback()
  }
}
export default {
  name: '后台项目Index',
  components: {
    comDialog,
  },
  mixins: [commonMixin],
  data() {
    return {
      params: { pageNum: 1, pageSize: 5, keyWord: '' },
      records: [],
      addDialogVisible: false,
      editDialogVisible: false,
      addForm: {
        avatar: '',
        createUser: '',
        email: '',
        nickName: '',
        password: '',
        phonenumber: '',
        salt: '',
        sex: '0',
        status: '0',
        userName: '',
        companyCode: '',
      },
      addFormRules: {
        nickName: [
          { required: true, message: '请输入昵称', trigger: 'blur' },
          {
            min: 2,
            max: 15,
            message: '长度在 2 到 15 个字符',
            trigger: 'blur',
          },
        ],
        userName: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          {
            min: 6,
            max: 15,
            message: '长度在 6 到 15 个字符',
            trigger: 'blur',
          },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            min: 6,
            max: 15,
            message: '长度在 6 到 15 个字符',
            trigger: 'blur',
          },
        ],
        sex: [
          {
            type: 'string',
            required: true,
            message: '请选择性别',
            trigger: 'change',
          },
        ],
        status: [
          {
            type: 'string',
            required: true,
            message: '请选择状态',
            trigger: 'change',
          },
        ],
        companyCode: [
          {
            type: 'string',
            required: true,
            message: '请选择公司',
            trigger: 'change',
          },
        ],

        phonenumber: [{ validator: checkPhone, trigger: 'blur' }],
        email: [{ validator: checkEmail, trigger: 'blur' }],
      },
      editFormRules: {
        nickName: [
          { required: true, message: '请输入昵称', trigger: 'blur' },
          {
            min: 2,
            max: 15,
            message: '长度在 2 到 15 个字符',
            trigger: 'blur',
          },
        ],
        userName: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          {
            min: 6,
            max: 15,
            message: '长度在 6 到 15 个字符',
            trigger: 'blur',
          },
        ],
        phonenumber: [{ validator: checkPhone, trigger: 'blur' }],
        email: [{ validator: checkEmail, trigger: 'blur' }],
      },
      userRoleFormRules: {
        selectedRoleId: [
          {
            required: true,
            message: '请选择角色',
            trigger: 'blur',
          },
        ],
      },
      editForm: {
        avatar: '',
        createUser: '',
        email: '',
        nickName: '',
        phonenumber: '',
        sex: '0',
        status: '0',
        userName: '',
        companyCode: 'xiaobao',
      },
      isRoleDialogVisible: false,
      addType: 1,
      userInfo: {},
      selectedRoleId: '',
      rolesList: [],
      companyList: [],
      isPassword: false,
      updatePasswordParams: {
        userId: '',
        original: '',
        password: '',
        confirm: '',
      },
      updatePasswordParamsRules: {
        original: [
          { required: true, message: '请输入之前的密码', trigger: 'blur' },
          {
            min: 6,
            max: 15,
            message: '长度在 6 到 15 个字符',
            trigger: 'blur',
          },
        ],
        password: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          {
            min: 6,
            max: 15,
            message: '长度在 6 到 15 个字符',
            trigger: 'blur',
          },
        ],
        confirm: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请再次输入密码'))
              } else if (value !== this.updatePasswordParams.password) {
                callback(new Error('两次输入密码不一致!'))
              } else {
                callback()
              }
            },
            trigger: 'blur',
          },
        ],
      },
    }
  },

  mounted() {
    this.findList()
    this.companyFindList()
  },

  methods: {
    addDialogClose() {
      this.resetFrom('addFormRef')
      this.addDialogVisible = false
    },

    addSaveRoleInfoDialogClose() {
      this.isRoleDialogVisible = false
    },
    isPasswordDialogClose() {
      this.isPassword = false
    },
    editDialogClose() {
      this.editDialogVisible = false
    },
    // 重置from
    resetFrom(fromRef) {
      this.$refs[fromRef].resetFields()
    },
    passwordClick(item) {
      const params = {
        userId: item.userId,
        original: '',
        password: '',
        confirm: '',
      }
      this.updatePasswordParams = params

      this.isPassword = true
    },
    async companyFindList() {
      const res = await this.$API.gongSi.get()
      if (res.status == 200 && res.data.length) {
        this.companyList = res.data
        // const list = [
        //   {
        //     companyCode: 'xiaobao',
        //     companyName: '晓葆',
        //   },
        // ]
        // this.companyList = [...list, ...res.data]
      }
    },
    async updatePasswordClick() {
      this.$refs['updatePasswordParamsRef'].validate(async (valid) => {
        if (valid) {
          const res = await this.$API.user.editPwd(this.updatePasswordParams)
          if (res.status !== 200) return this.$message.error(res.msg)
          this.$message.success(res.data)
          this.isPassword = true
        } else {
          return this.$message.error('必填项不能为空')
        }
      })
    },
    showEditClick(row) {
      this.editForm = row
      this.editDialogVisible = true
    },
    async showAddClick() {
      this.addDialogVisible = true
    },

    async addUser() {
      this.$refs['addFormRef'].validate(async (valid) => {
        if (valid) {
          const res = await this.$API.user.add(this.addForm)
          if (res.status !== 200) return this.$message.error(res.msg)
          this.$message.success('添加成功')
          this.addDialogClose()
          this.findList()
        } else {
          return this.$message.error('必填项不能为空')
        }
      })
    },
    async updateById() {
      this.$refs['editFormRef'].validate(async (valid) => {
        if (valid) {
          const res = await this.$API.user.update(this.editForm)
          if (res.status == 200) {
            this.editDialogVisible = false
            this.findList()
          }
        } else {
          return this.$message.error('必填项不能为空')
        }
      })
    },
    async findList() {
      const res = await this.$API.user.get(this.params)
      if (res.status == 200) {
        this.records = res.data
      }
    },
    async deleteInfoClick(item) {
      const isTrue = await this.deleteClick()
      if (!isTrue) return ''
      this.deleteInfo(item)
    },
    async deleteInfo(item) {
      const params = {
        userId: item.userId,
      }
      const res = await this.$API.user.delete(params)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.$message.success(res.data)
      this.params.pageNum = 1
      this.findList()
    },
    // 展示分配角色的对话框
    async setRole(userInfo) {
      this.userInfo = userInfo
      // 在展示对话框之前，获取所有角色的列表
      const res = await this.$API.role.get()
      if (res.status == 200) {
        console.log(123)
        this.isRoleDialogVisible = true
        this.rolesList = res.data
      } else {
        return this.$message.error('获取角色列表失败！')
      }
    },

    // 点击按钮，分配角色
    async saveRoleInfo() {
      if (!this.selectedRoleId) {
        return this.$message.error('请选择要分配的角色！')
      }
      const params = {
        roleId: this.selectedRoleId,
        userId: this.userInfo.userId,
      }
      const res = await this.$API.user.saveUserRole(params)

      if (res.status !== 200) {
        return this.$message.error('更新角色失败！')
      }

      this.$message.success('更新角色成功！')
      this.findList()
      this.isRoleDialogVisible = false
    },
  },
}
</script>

<style lang="scss" scoped></style>
